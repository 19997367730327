<template>
    <v-container fill-height fluid text-xs-center>
        <v-card class="fill-height container--fluid" width="100vw" flat outlined>
            <v-data-table
                :headers="headers"
                :items="sliders"
                :items-per-page="-1"
                :search="searchSlider"
                :loading="loadingData"
                item-key="id"
                sort-by="id"
                disable-sort
                no-data-text="No hay datos para mostrar..."
                loading-text="Cargando los datos, por favor espere!"
                class="elevation-1"
                :footer-props="{
                      showFirstLastPage: false,
                      disableItemsPerPage: false,
                      itemsPerPageAllText: 'Todas',
                      itemsPerPageText: 'Filas por página:'
                    }">
                <template v-slot:top>
                    <v-toolbar flat class="align-center">
                        <v-toolbar-title class="text-subtitle-1 text-md-h5">
                            Sliders del Portal Principal
                        </v-toolbar-title>
                        <v-divider
                            class="mx-4"
                            inset
                            vertical>
                        </v-divider>
                        <v-spacer></v-spacer>
                        <v-responsive max-width="300" height="45" class="hidden-sm-and-down">
                            <v-text-field
                                id="findtext-slider"
                                label="Buscar.."
                                name="findslider"
                                outlined
                                dense
                                single-line
                                clearable
                                background-color="white"
                                hide-details
                                v-model="searchSlider"
                                class="white--text mt-0 pt-0 mr-2"
                                prepend-inner-icon="mdi-magnify"></v-text-field>
                        </v-responsive>
                        <v-dialog :fullscreen="$vuetify.breakpoint.smAndDown"
                                  v-model="dialog"
                                  max-width="500px"
                                  persistent>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn :small="$vuetify.breakpoint.smAndDown"
                                       color="blue darken-1"
                                       dark
                                       depressed
                                       elevation="0"
                                       class="mb-2"
                                       v-bind="attrs"
                                       v-on="on"
                                       @click="newItem">
                                    Adicionar
                                </v-btn>
                            </template>
                            <v-card>
                                <v-form
                                    ref="form"
                                    v-model="valid"
                                    lazy-validation>
                                    <v-card-title class="text-h6 grey lighten-2">
                                        <span class="headline">{{ formTitle }}</span>
                                    </v-card-title>
                                    <v-divider></v-divider>
                                    <v-card-text>
                                        <v-container>
                                            <v-row>
                                                <v-col cols="12">
                                                    <v-text-field
                                                        dense
                                                        v-model="editedItem.title"
                                                        label="Título"
                                                        :rules="textRules"
                                                        required
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="12">
                                                    <v-textarea
                                                        dense
                                                        v-model="editedItem.sub_title"
                                                        label="SubTítulo"
                                                        :rules="textRules"
                                                        no-resize
                                                        rows="2"
                                                        counter
                                                        required>
                                                    </v-textarea>
                                                </v-col>
                                            </v-row>
                                            <v-col cols="12">
                                                <v-row no-gutters align="center" justify="center">
                                                    <v-img
                                                        :src="imagePreview != null ? imagePreview : require('@/assets/images/no-image.jpg')"
                                                        :lazy-src="require('@/assets/images/no-image.jpg')"
                                                        max-height="150"
                                                        max-width="100%"
                                                        :aspect-ratio="1"
                                                        class="white--text align-center justify-center elevation-2">
                                                    </v-img>
                                                </v-row>
                                                <v-row no-gutters align="center" justify="center">
                                                    <v-file-input
                                                        dense
                                                        v-model="imageFile"
                                                        accept="image/png, image/jpeg, image/bmp"
                                                        show-size
                                                        clearable
                                                        :rules="imageRules"
                                                        label="Cargar/Cambiar imágen"
                                                        placeholder="Archivo de imágen"
                                                        prepend-icon="mdi-camera"
                                                        @change="changePreviewImage()">
                                                    </v-file-input>
                                                </v-row>
                                            </v-col>
                                            <v-col cols="12">
                                                <v-checkbox
                                                    dense
                                                    v-model="editedItem.show_link"
                                                    label="Activar Enlace?"
                                                ></v-checkbox>
                                            </v-col>
                                        </v-container>
                                    </v-card-text>
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn color="primary"
                                               text
                                               @click="close">
                                            Cancelar
                                        </v-btn>
                                        <v-btn :disabled="!valid"
                                               color="primary"
                                               text
                                               @click="save">
                                            Guardar
                                        </v-btn>
                                    </v-card-actions>
                                </v-form>
                            </v-card>
                        </v-dialog>
                        <v-dialog v-model="dialogDelete" max-width="500px">
                            <v-card>
                                <v-card-title class="text-h6 headline">Esta seguro que desea eliminar este elemento?
                                </v-card-title>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="blue darken-1" text @click="closeDelete">Cancelar</v-btn>
                                    <v-btn color="blue darken-1" text @click="deleteItemConfirm">Proceder</v-btn>
                                    <v-spacer></v-spacer>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-toolbar>
                </template>
                <template v-slot:item.enabled="{ item }">
                    <v-simple-checkbox
                        v-model="item.enabled"
                        disabled
                    ></v-simple-checkbox>
                </template>
                <template v-slot:item.actions="{ item }">
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                v-bind="attrs"
                                v-on="on"
                                small
                                class="mr-2"
                                @click="editItem(item)">
                                mdi-pencil
                            </v-icon>
                        </template>
                        <span>Editar</span>
                    </v-tooltip>
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                v-bind="attrs"
                                v-on="on"
                                small
                                class="mr-2"
                                @click="deleteItem(item)">
                                mdi-delete
                            </v-icon>
                        </template>
                        <span>Eliminar</span>
                    </v-tooltip>
                </template>
                <template v-slot:no-data>
                    <span class="text-h5">No hay registros en el listado!</span>
                    <br/>
                    <v-btn color="primary" @click="getSliders">
                        Recargar
                    </v-btn>
                </template>
            </v-data-table>
            <v-snackbar
                v-model="snackBar"
                :timeout="2000">
                {{ snackText }}
                <template v-slot:action="{ attrs }">
                    <v-btn v-bind="attrs"
                           icon
                           @click="snackBar = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </template>
            </v-snackbar>
        </v-card>
    </v-container>
</template>

<script>
    import bannerService from "@/providers/BannerService";

    export default {
        name: "BannerModuleComponent",
        data: () => ({
            snackBar: false,
            snackText: '',
            valid: true,
            searchSlider: '',
            loadingData: false,
            dialog: false,
            dialogDelete: false,
            dialogError: false,
            messageError: '',
            imageFile: null,
            imagePreview: null,
            selectedImageIndex: -1,
            sliders: [],
            headers: [
                {text: 'Id', value: 'id', align: ' d-none'}, // para ocultar la columna Id.
                {
                    text: 'Titulo',
                    align: 'start',
                    sortable: false,
                    value: 'title',
                },
                {
                    text: 'Contenido',
                    align: 'start',
                    sortable: false,
                    value: 'sub_title',
                },
                {text: 'Acciones', value: 'actions', width: 100, align: 'center', sortable: false},
            ],
            editedIndex: -1,
            editedItem: {
                id: 0,
                title: "",
                sub_title: "",
                show_link: false,
                image_url: ""
            },
            defaultItem: {
                id: 0,
                title: "",
                sub_title: "",
                show_link: false,
                image_url: ""
            },
            textRules: [
                v => !!v || 'El valor es obligatorio!'
                // v => (v && v.length >= 10) || 'El Nombre debe tener mas de un caracter!',
            ],
            imageRules: [
                value => !value || value.size < 2000000 || 'El tamaño de la imagen no debe de sobrepasar los 2 MegaBytes!'
            ]
        }),
        computed: {
            formTitle() {
                return this.editedIndex === -1 ? 'Nuevo Item' : 'Editar Item';
            },
        },
        watch: {
            dialog(val) {
                val || this.close();
            },
            dialogDelete(val) {
                val || this.closeDelete();
            },
        },
        methods: {
            getItemText(item) {
                return `${item.name} ${item.last_name}`;
            },
            getSliders() {
                this.loadingData = true;
                bannerService.getAllRecords().then(record => {
                    this.sliders = record.value;
                    this.loadingData = false;
                });
            },
            newItem() {
                this.valid = false;
                this.imageFile = null;
                this.imagePreview = null;
                this.selectedImageIndex = -1;
                this.editedItem = Object.assign({}, this.defaultItem);
            },
            editItem(item) {
                this.editedIndex = this.sliders.indexOf(item);
                this.editedItem = Object.assign({}, item);
                this.imagePreview = null;
                this.selectedImageIndex = -1;
                if (this.editedItem.image_url !== null) {
                    this.imagePreview = this.editedItem.image_url;
                    this.selectedImageIndex = 0;
                }
                this.imageFile = null;
                this.dialog = true;
            },
            deleteItem(item) {
                this.editedIndex = this.sliders.indexOf(item);
                this.editedItem = Object.assign({}, item);
                this.dialogDelete = true;
            },
            deleteItemConfirm() {
                bannerService.deleteRecord(this.editedItem.id).then(
                    response => {
                        if (response.success) {
                            this.sliders.splice(this.editedIndex, 1);
                            this.snackText = 'Registro eliminado con éxito.';
                        } else {
                            this.snackText = 'Un error impidió eliminar el registro!';
                        }
                    }
                )
                this.closeDelete();
                this.snackBar = true;
            },
            close() {
                this.dialog = false;
                this.$nextTick(() => {
                    this.editedItem = Object.assign({}, this.defaultItem);
                    this.editedIndex = -1;
                    this.imagePreview = null;
                    this.selectedImageIndex = -1;
                });
            },
            closeDelete() {
                this.dialogDelete = false;
                this.$nextTick(() => {
                    this.editedItem = Object.assign({}, this.defaultItem);
                    this.editedIndex = -1;
                });
            },
            save() {
                if (this.imagePreview == null) {
                    // console.log('El formulario no contiene un logo asociado!!');
                    this.messageError = 'El formulario no contiene una imagen asociada. Por favor agrege una!!';
                    this.dialogError = true;
                    return;
                }
                this.$refs.form.validate();
                if (this.$refs.form.validate(true)) {
                    // console.log('editedItem: ', this.editedItem);
                    if (this.editedIndex > -1) {
                        // Actualizar el registro por el metodo PUT
                        Object.assign(this.sliders[this.editedIndex], this.editedItem);
                        bannerService.updateRecord(this.editedItem, this.imageFile).then(
                            response => {
                                console.log('response: ', response);
                                if (response.success) {
                                    this.getSliders();
                                }
                            }
                        );
                    } else {
                        // Agrega el registro por el metodo POST
                        this.sliders.push(this.editedItem);
                        bannerService.addRecord(this.editedItem, this.imageFile).then(
                            response => {
                                if (response.success) {
                                    this.getSliders();
                                }
                            }
                        )
                    }
                    this.close();
                    this.snackText = 'Registro guardado con éxito.';
                    this.snackBar = true;
                }
            },
            changePreviewImage() {
                if (this.imageFile) {
                    // console.log('Data imagen: ', this.imageFile, '\n');
                    if (this.imageFile.size > 2000000) {
                        this.imagePreview = null;
                        return;
                    }
                    let reader = new FileReader();
                    reader.onload = () => {
                        this.imagePreview = reader.result;
                        // // console.log('Preview imagen: ',this.imagePreview, '\n');
                    };
                    reader.readAsDataURL(this.imageFile);
                    // console.log('Imagen actualizada: ', this.imageFile.name);
                    this.snackText = 'Imágen de la seccion cargada con éxito...';
                    this.snackBar = true;
                }
            }
        },
        mounted() {
            this.sliders = [];
            this.getSliders();
        }
    }
</script>

<style scoped>
    .v-btn {
        text-transform: none !important;
    }
</style>
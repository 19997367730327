<template>
    <banner-module-component/>
</template>

<script>
    import BannerModuleComponent from "@/components/admin/BannerModuleComponent";
    
    export default {
        name: "BannerModule",
        title: "Banner HomePage | IE Security",
        components: {BannerModuleComponent}
    }
</script>

<style scoped>

</style>